// extracted by mini-css-extract-plugin
export var h1Typography = "popup-module--h1-typography--x9p11";
export var header = "popup-module--header--nEWKP";
export var h2Typography = "popup-module--h2-typography--iyT8u";
export var h2TypographySecond = "popup-module--h2-typography-second--rN4vz";
export var h3Typography = "popup-module--h3-typography--xXqBE";
export var h3TypographyError = "popup-module--h3-typography-error--zsW9D";
export var h4Typography = "popup-module--h4-typography--OAoDI";
export var pTypography = "popup-module--p-typography--OEkuT";
export var contentContainer = "popup-module--content-container--XvSse";
export var contentContainerDelete = "popup-module--content-container-delete--Xp7rJ";
export var smallPTypography = "popup-module--small-p-typography--3btrp";
export var sidebarTypography = "popup-module--sidebar-typography--f3ySA";
export var errorTypography = "popup-module--error-typography--dzECx";
export var captionTypography = "popup-module--caption-typography--SABQc";
export var authMessageLabelTypography = "popup-module--auth-message-label-typography--i354N";
export var authMessageTypography = "popup-module--auth-message-typography--kJcDD";
export var versionInfoTypography = "popup-module--version-info-typography--6KWqj";
export var itemHidden = "popup-module--item-hidden--eJ+Zs";
export var ___gatsby = "popup-module--___gatsby--9mo9m";
export var gatsbyFocusWrapper = "popup-module--gatsby-focus-wrapper--1-3KC";
export var info = "popup-module--info--MJk2w";
export var buttonsContainer = "popup-module--buttons-container--S5Jbp";
export var buttonsContainerDelete = "popup-module--buttons-container-delete--EgJFD";
export var buttonsContainerSpaceBetween = "popup-module--buttons-container-space-between--tD7Wl";
export var spaceBetweenButtons = "popup-module--space-between-buttons--ywDle";
export var jsonContent = "popup-module--json-content--EH6Az";